import React from 'react'
import Layout from '../components/Layout'
import MvTextPage from '../components/common/MvTextPage'
import MvTextPageSection from '../components/common/MvTextPageSection'

export default () => (
  <Layout>
    <MvTextPage title="Impressum">
      <MvTextPageSection>
        <p>
          Anbieter und verantwortlich für diese Internet Domain im Sinne des §6
          des Gesetzes Über die Nutzung von Telediensten (TDG) des Gesetztes zur
          Regelung der Rahmenbedingungen für Informations- und
          Kommunikationsdienste (IuKDG), und bezüglich redaktioneller Inhalte,
          des Presserechts, sowie des §6 II des Staatsvertrages über
          Mediendienste (MDStV), ist:
        </p>
        <p className="my-4 ml-4 font-bold">
          Musikverein Trachtenkapelle Winterbach 1880 e.V <br />
          Robin Unrath <br />
          Raingärten 4 <br />
          73650 Winterbach <br />
        </p>
        <p>Vertreten durch den Vorstand: </p>
        <p className="my-4 ml-4">
          Robin Unrath, Christian Engelhardt, Carmen Grüblbauer, Nina
          Bettighofer und Katja Worms
        </p>
        <p>Eingetragen beim Amtsgericht Stuttgart VR 280089</p>
      </MvTextPageSection>

      <MvTextPageSection title="Rechtliche Hinweise">
        <p>
          Die auf dieser Website bereitgestellten Informationen werden durch uns
          sorgfältig geprüft und regelmäßig aktualisiert. Dennoch können wir
          keine Haftung oder Garantie für die Aktualität, Richtigkeit und
          Vollständigkeit der zur Verfügung gestellten Informationen übernehmen.
        </p>
        <p>
          Gleiches gilt auch für den Inhalt der Websites, auf die mittels
          Hyperlink verwiesen wird. Der Musikverein Trachtenkapelle Winterbach
          e.V. ist für den Inhalt der Websites, die aufgrund einer solchen
          Verbindung erreicht werden, nicht verantwortlich.{' '}
        </p>
        <p>
          Die Vervielfältigung von Informationen und Daten, insbesondere die
          Verwendung von Texten, Textteilen und Fotos bedarf der vorherigen
          Zustimmung des Musikvereins.
        </p>
      </MvTextPageSection>

      <MvTextPageSection title="Datenschutz">
        Alles zum Thema Datenschutz finden Sie in unserer{' '}
        <a href="/datenschutz" title="zur Datenschutzerklärung">
          Datenschutzerklärung
        </a>
      </MvTextPageSection>

      <MvTextPageSection title="Fotos">
        <p>
          Felix Pilz, Daniel Stimac, Jean-Paul Martin, Ernst Schulz, Bernd
          Waldheim, Marielies Ewersmeyer, Michael Ammersinn, Thomas Kreisl,
          privat
        </p>
      </MvTextPageSection>

      <MvTextPageSection title="Realisierung">
        <p>
          Benjamin Rupp | webmaster@mv-winterbach.de |{' '}
          <a href="https://www.benjaminrupp.de" rel="noopener">
            www.benjaminrupp.de
          </a>
        </p>
      </MvTextPageSection>
    </MvTextPage>
  </Layout>
)
